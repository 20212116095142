<template>
    <div class="check_list_form">
        <div class="check_list_form-header">
            <h3>שם: {{check_list_data.name}}</h3>
            <h3>תיאור: {{check_list_data.description}}</h3>
            <h3>תפקיד: {{check_list_data.role}}</h3>
            <div @click="$emit('close')" class="back-btn">
                <i class="material-icons">arrow_back</i>
            </div>
        </div>
        <div class="check_list_form-content">
            <template v-for="(list,index) in check_list_data.check_lists" :key="index">
                <List :list="list" />
            </template>
        </div>
        <div class="check_list_form-footer">
            <el-button @click="handle_submit" style="width:50%" type="success">עדכון</el-button>
        </div>

        <!-- JUST FOR MAIL -->
    <div class="form-email" id="form-email" v-show="false">
      <table
        style="width:100%; border-collapse: collapse; font-size: 1.1rem; margin: 1rem 0;"
      >
        <tr style="background-color: #dddddd;">
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            שם הצ'ק ליסט
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{check_list_data.name}}
          </td>
        </tr>
        <tr>
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            תיאור הצ'ק ליסט
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{check_list_data.description}}
          </td>
        </tr>
        <tr style="background-color: #dddddd;">
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            תפקיד
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{check_list_data.role}}
          </td>
        </tr>
        <template v-for="(list,index) in  check_list_data.check_lists" :key="index">
            <tr style="margin-top:10px;" :style="index%2==0?{'background': '#dddddd'}:{'background': 'unset'}">
                <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">דרישה</th>
                <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">{{list.desc}}</td>
            </tr>
            <tr style="margin-top:10px;" :style="index%2==0?{'background': '#dddddd'}:{'background': 'unset'}">
                <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">תגובה</th>
                <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">{{list.answer}}</td>
            </tr>
            <tr v-if="list.comment" style="margin-top:10px;" :style="index%2==0?{'background': '#dddddd'}:{'background': 'unset'}">
                <th style="border:1px solid #dddddd; text-align:right; padding: 8px; color:red;">הערות</th>
                <td style="border:1px solid #dddddd; text-align:right; padding: 8px; color:red;">{{list.comment}}</td>
            </tr>
            <template v-for="img in list.imges_url" :key="img">
                <tr style="margin-top:10px;" :style="index%2==0?{'background': '#dddddd'}:{'background': 'unset'}">
                    <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">תמונה</th>
                    <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                        <img style="width:100px" :src="img">
                    </td>
                </tr>
            </template>
        </template>
      </table>
    </div>
    </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import List from './Form/List.vue'
import {slide_pop_error,alert} from '../../../../../Methods/Msgs'
import {save_new_check_list_in_db,user} from '../../Scripts/Client/scripts'
import {projectFunctions} from '../../../../../firebase/config'

export default {
    components:{List},
    props:['check_list_data'],
    emits:['close'],
    setup(props,{emit}){

        const err_msg = ref('')

        const validation = ()=>{
            for(const list of props.check_list_data.check_lists){
                if(!list.answer && list.type=='bool'){
                    err_msg.value = `נא לסמן כן/לא ב${list.desc}!`
                    return false
                }
                if(!list.answer && list.type=='str'){
                    err_msg.value = `נא להזין טקסט בשדה ב${list.desc}!`
                    return false
                }
                if(list.must_image && list.imges_url.length==0){
                    err_msg.value = `נא להעלות לפחות תמונה אחת!`
                    return false
                }
            }

            return true
        }
        const handle_submit = async()=>{
            if(!validation()){
                slide_pop_error(err_msg.value)
            }else{
                await save_new_check_list_in_db(props.check_list_data)
                if(props.check_list_data.email_report == 'כן'){
                    console.log(props.check_list_data.email_reports);
                    sendEmail(props.check_list_data.email_reports)
                }
                alert('success','הצלחה',
                'צק ליסט נשלח בהצלחה')
                .then(()=>{
                    emit('close')
                })
            }
        }

        function sendEmail(email_reports) {
          projectFunctions.httpsCallable('send_email')({
              html: document.getElementById("form-email").innerHTML,
              destinations:['yevgeny@rosman.co.il','mosescorcias@gmail.com',...email_reports],
              from:"מערכת צ'ק ליסט",
              title:`הוזן צ'ק ליסט בשם ${props.check_list_data.name} ע"י ${user.value.display_name}, סניף:${props.check_list_data.branche}`
          })
        }

        return{
            handle_submit,
            err_msg
        }
    }
}
</script>

<style scoped>
    .check_list_form{
        padding: 5px 0;
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
        height: 100%;
        color: #333;
    }
    .check_list_form-header{
        position: relative;
        width: 100%;
        height: 100px;
        background: whitesmoke;
        border-radius: 10px;
        padding: 5px;
        line-height: 1.5;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    }
    @media screen and (max-width: 600px) {
        .check_list_form-header{
            line-height: 1.2
        }
    }
    .back-btn{
        position: absolute;
        top: 5px;
        left: 5px;
        width: 30px;
        height: 30px;
        background: var(--danger);
        color: #fff;
        border-radius: 50%;
        cursor: pointer;
        z-index: 10;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .check_list_form-content{
        width: 100%;
        height: calc(100% - 150px);
        padding: 5px 0;
        overflow:hidden;
        overflow-y: auto;
        color: #fff;
    }
    .check_list_form-footer{
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--secondary);
        border-radius: 5px;
    }
</style>