<template>
  <div class="list">
      <div v-if="list.type=='bool'" class="list-with-radio">
        <h3>{{list.desc}}</h3>
        <div  class="mb-2 flex items-center">
            <el-radio-group v-model="list.answer" class="ml-4">
                <el-radio style="color:#fff;" label="כן" size="large">כן</el-radio>
                <el-radio style="color:#fff;" label="לא" size="large">לא</el-radio>
            </el-radio-group>
        </div>
      </div>
      <div v-if="list.type=='str'" class="list-with-text">
          <h3>{{list.desc}}</h3>
          <el-input :style="[platform=='mobile' ? {'width':'100%'} : {'width':'30%'}]" v-model="list.answer" v-if="list.type=='str'" placeholder="רשום כאן" />
      </div>
      <div v-if="list.comment" class="list-with-comment">
          <h3>הערות: <span class="comment">{{list.comment}}</span></h3>
      </div>
      <div v-if="list.img" class="list-image-upload">
        <h3 v-if="list.must_image">העלאת תמונה <span style="color:red;">חובה*</span></h3>
        <h3 v-if="!list.must_image">העלאת תמונה <span style="color:#28c76f">אופציונאלי</span></h3>
        <el-button  @click="handle_upload" v-if="list.img && !is_upload" style="width:30%; margin-top:10px;" type="info">העלה תמונה</el-button>
        <el-button  @click="handle_upload" v-if="is_upload" style="width:30%; margin-top:5px;" type="info" disabled>טוען...</el-button>
        <div v-if="list.imges_url.length>0"  class="list-image-upload-images">
            <template v-for="(img,index) in list.imges_url" :key="img">
                <div class="image">
                    <img :src="img">
                    <div @click="handle_delete_img(img,index)" class="remove-btn">
                        <i class="material-icons">delete</i>
                    </div>
                </div>
            </template>
        </div>
      </div>

      <input ref="upload" v-show="false" @change="handle_change" type="file">
      <i @click="handle_add_comment" class="material-icons comment-icon" :class="[list.comment ? 'commented' : '']">comment</i>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import store from '../../../../../../store'
import { computed } from '@vue/runtime-core'
import upload_image_storage from '../../../../../../Methods/use_general_storage'
import {slide_pop_error} from '../../../../../../Methods/Msgs'
import Swal from 'sweetalert2'

export default {
    props:['list'],
    setup(props){
        const upload = ref()

        const is_upload = ref(false)

        const {url,delete_image_by_url,uploadImage} = upload_image_storage()

        const platform = ref(computed(()=>{
            return store.state.platform
        }))

        const handle_upload = () => {
            if(props.list.imges_url.length<3){
                upload.value.click()
                return
            }
            slide_pop_error('ניתן להעלות עד 3 תמונות בלבד!')
        }

        const handle_change = async(e) => {
            const types = ['image/png','image/jpeg','image/jpg']
            const selected=e.target.files[0]
            if(selected && types.includes(selected.type)){
               is_upload.value = true
               await uploadImage(selected,`CheckLists/${props.list.desc}/${new Date().toLocaleDateString('he')}`)
               props.list.imges_url.push(url.value)
               is_upload.value = false
            }else{
                slide_pop_error('עליך להעלות קובץ תמונה - JPG, PNG, JPEG')
            }

        }

        const handle_delete_img = async(img,index)=>{
            await delete_image_by_url(img)
            props.list.imges_url.splice(index,1)
        }
        
        const handle_add_comment = async()=>{
            await Swal.fire({
                input: 'textarea',
                inputLabel: 'הוספת הערה אופציונאלי',
                inputPlaceholder: `${props.list.comment?props.list.comment:'הוסף הערה חדשה....'}`,
                inputAttributes: {
                    'aria-label': 'Type your message here'
                },
                showCancelButton: true,
                cancelButtonText: 'ביטול',
                confirmButtonText: 'עדכון',
                confirmButtonColor: '#28c76f',
                reverseButtons:true
            }).then((res)=>{
                if(res.isConfirmed){
                    if(res.value) props.list.comment = res.value
                    else delete props.list.comment
                }
            })
        }

        return{
            handle_upload,
            handle_change,
            platform,
            handle_delete_img,
            is_upload,
            upload,
            handle_add_comment
        }
    }
}
</script>

<style scoped>
    .list{
        position: relative;
        width: 100%;
        height: fit-content;
        margin-bottom: 5px;
        background: var(--secondary);
        border-radius: 5px;
        color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .comment-icon{
        position: absolute;
        top: 5px;
        left: 5px;
        user-select: none;
        font-size: 25px;
        color: var(--warning);
        cursor: pointer;
        z-index: 10;
    }
    .comment-icon.commented{
        color: var(--success);
    }
    .comment{
        color: var(--warning);
    }
    .list-with-radio,.list-with-comment{
        width: 100%;
        display: flex;
        align-items: center;
        margin: 5px 0;
        padding: 0 5px;
    }
    .list-image-upload,.list-with-text{
        padding: 0 5px;
        margin: 5px 0;
        width: 100%;
    }
    .list-image-upload-images{
        margin-top: 10px;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3,1fr);
        grid-auto-rows: 100px;
        grid-gap: 5px;
    }
    .list-image-upload-images .image{
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .list-image-upload-images img{
        max-width: 100%;
        max-height: 100%;
    }

    .remove-btn{
        position: absolute;
        top: 0;
        right: 5px;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        background: var(--danger);
        border-radius: 50%;
        cursor: pointer;
        z-index: 10;
    }
    :global(.el-radio__label){
        padding-left: 0;
        padding-right: 10px;
    }
    :global(.el-radio){
        margin-left: 10px;
    }
</style>