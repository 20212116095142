<template>
  <div class="check-lists-main">
      <div class="check-lists-main-header">
          <h2>צ'ק ליסט</h2>
      </div>
      <div class="check-lists-main-content">
          <component @close="state='DisplayChecklists'" :is="state" :check_list_data="check_list_data" 
          :check_lists="check_lists" @list="handle_show_check_list" />
      </div>

      <div v-if="show_branche_box" class="branches">
          <div class="branches-box">
              <div class="branches-box-header">
                  <h2>נא לבחור סניף</h2>
                  <div @click="show_branche_box=false" class="exit-btn">
                      X
                  </div>
              </div>
              <div class="branches-box-input">
                <h3>שם הסניף</h3>
                 <el-select v-model="selected_branche" filterable  class="m-2" placeholder="בחירת סניף" size="large">
                    <el-option
                    v-for="branche in branches"
                    :key="branche"
                    :value="branche"
                    />
                </el-select>
                <el-button @click="handle_choose_branche" style="margin-top:5px; width:50%;" type="success">בחירה</el-button>
              </div>
            
          </div>
      </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import {get_all_branches, get_check_lists_formts_from_db} from '../../Scripts/Client/scripts'
import DisplayChecklists from './DisplayChecklists.vue'
import CheckListForm from './CheckListForm.vue'
export default {
    components:{DisplayChecklists,CheckListForm},
    setup(){
        
        const state = ref('DisplayChecklists')
        const check_list_data = ref(null)
        const branches = ref([])
        const selected_branche = ref('')
        const show_branche_box = ref(false)
        const selected_list = ref(null)

        const check_lists = ref([])

        const handle_choose_branche = ()=>{
            if(selected_branche.value){
                check_list_data.value = JSON.parse(JSON.stringify(selected_list.value))
                check_list_data.value.start_time = new Date()
                check_list_data.value.branche = selected_branche.value
                show_branche_box.value = false
                state.value = 'CheckListForm'
            }
        }
        const handle_show_check_list = (list)=>{
            selected_list.value = list
            show_branche_box.value = true
            
        }

        const init = async()=>{
            check_lists.value = await get_check_lists_formts_from_db()
            branches.value  = await get_all_branches()
        }

        init()

        return{
            handle_show_check_list,
            check_lists,
            state,
            check_list_data,
            branches,
            selected_branche,
            handle_choose_branche,
            show_branche_box,
            selected_branche
        }
    }
}
</script>

<style scoped>
    .check-lists-main{
        position: relative;
        width: 100%;
        max-width: 1200px;
        height: 100%;
        margin: 0 auto;
        color: #fff;
        padding: 5px;
    }
    .check-lists-main-header{
        width: 100%;
        height: 50px;
        border-radius: 5px;
        background: var(--secondary);
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .check-lists-main-content{
        width: 100%;
        height: calc(100% - 50px);
    }
    .branches{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        z-index: 10;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 5px;
    }
    .branches-box{
        width: 100%;
        max-width: 400px;
        background: #fff;
        border-radius: 5px;
    }
    .branches-box-header{
        position: relative;
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        background: var(--success);
        border-radius: 5px 5px 0 0;
        color:#fff;
    }
    .exit-btn{
        position: absolute;
        top: 5px;
        left: 5px;
        background: var(--danger);
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        z-index: 9;
        cursor: pointer;
        color: #fff;
    }
    .branches-box-input{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #333;
        padding: 10px 0;
    }
</style>